const Slugify = (text, ampersand = "and") => {
  const a = "àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ";
  const b = "aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh";
  const p = new RegExp(a.split("").join("|"), "g");

  return text
    .toString()
    .toLowerCase()
    .replace(/[\s_]+/g, "-")
    .replace(p, c => b.charAt(a.indexOf(c)))
    .replace(/&/g, `-${ampersand}-`)
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+|-+$/g, "");
};

export default Slugify;
