<template>
  <v-card v-if="product">
    <v-toolbar dark flat color="teal">
      <v-btn icon dark @click="cancel()">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title>Update Product</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-switch hide-details dark block label="Active" v-model="product.active"></v-switch>
      <v-toolbar-items>
        <v-btn dark text @click="updateProduct(product)">Update</v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col cols="12" md="8">
          <v-row>
            <v-col cols="12">
              <v-text-field outlined v-model.trim="product.name" label="Name"></v-text-field>
              <!-- <v-text-field :value="generateSlug" disabled label="URL"></v-text-field> -->
              <v-row>
                <v-col cols="6" md="4">
                  <v-text-field outlined v-model.trim="product.sku" label="SKU"></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                  <v-text-field outlined v-model.number="product.stock" type="number" label="Stock"></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                  <v-text-field outlined v-model.number="product.price" type="number" label="Price"></v-text-field>
                </v-col>
              </v-row>
              <quill-editor v-model="product.description"></quill-editor>
              <v-col v-if="product.features.length" class="px-0">
                <v-card>
                  <v-toolbar dark flat color="teal">
                    <v-toolbar-title>Features</v-toolbar-title>
                  </v-toolbar>
                  <v-simple-table>
                    <tbody>
                      <tr v-for="item in product.features" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td>{{ item.option }}</td>
                        <td>
                          <v-btn @click="removeFeature(item.option, index)" icon>
                            <v-icon>clear</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4">
          <v-row>
            <v-col cols="12">
              <v-select
                outlined
                :items="allProductCategories"
                v-model="this.category"
                item-text="name"
                return-object
                label="Category"
                @change="setCategory"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-card v-if="features.length !== 0">
                <v-toolbar dark flat color="teal">
                  <v-toolbar-title>Features</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="d-flex" v-for="(feature, index) in features" :key="index">
                  <v-select
                    class="mr-5"
                    outlined
                    v-model="option"
                    :items="feature.options"
                    :label="feature.name"
                    :disabled="optionIndex != index"
                  ></v-select>
                  <v-btn
                    :disabled="optionIndex != index"
                    outlined
                    height="56"
                    @click="setFeature(feature.name)"
                    color="teal"
                  >Add</v-btn>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="8">
              <v-file-input
                show-size
                v-model="images"
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Select images"
                prepend-icon="mdi-camera"
                label="Images"
                outlined
                color="primary"
              ></v-file-input>
              <!-- <span v-if="progress != null">{{progress()}}</span> -->
            </v-col>
            <v-col cols="4">
              <v-btn
                color="teal"
                height="56"
                v-if="progress == 0"
                block
                large
                outlined
                @click="uploadImage()"
              >Upload</v-btn>
              <v-progress-circular
                v-else
                size="56"
                color="primary"
                v-model="progress"
              >{{ parseInt(progress) }}</v-progress-circular>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" v-for="(image, index) in product.images" :key="image">
              <v-img height="80" contain :src="image">
                <v-icon small color="primary" @click="deleteImage(image, index)">clear</v-icon>
              </v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <Snackbar :snackbar="snackbar" />
    </v-container>
  </v-card>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

import firebase from "firebase";
//import { db } from "@/db"

import { mapGetters, mapActions } from "vuex";
import slugify from "./slugify";

export default {
  name: "AddProducts",
  components: {
    quillEditor
  },
  data() {
    return {
      id: this.$route.params.id,
      progress: 0,
      features: [],
      option: "",
      optionIndex: 0,
      snackbar: {
        isActive: false, //Activator
        x: "", // left or right or ""
        y: "", // top or bottom or ""
        color: "", // Any Color or ""
        mode: null, //vertical or multi-line or null
        // timeout: null, // set timeout Ex: 60000
        text: "" //Error Message
      },
      images: null
    };
  },
  computed: {
    ...mapGetters(["allProductCategories", "allProducts"]),

    product() {
      return this.allProducts.find(product => product.id == this.id);
    },

    // generateSlug() {
    //   const slugData = this.product.name + "_" + this.product.sku;
    //   return (this.product.slug = this.slugify(slugData));
    // },
    category() {
      if (this.product.category.id != "") {
        const category = this.allProductCategories.find(category => {
          return category.id == this.product.category.id;
        });
        this.updateFeatures(category.features);

        return category;
      } else return "";
    }
  },
  methods: {
    updateFeatures(data) {
      this.features = data;
    },
    ...mapActions(["update"]),
    removeFeature(option, position) {
      this.product.features.splice(position, 1);
      const index = this.product.tags.indexOf(option);
      if (index > -1) {
        this.product.tags.splice(index, 1);
      }
    },
    setCategory(category) {
      this.resetFeatures();
      this.product.category = {
        id: category.id,
        active: category.active,
        name: category.name,
        description: category.description,
        image: category.image,
        slug: category.slug
      };
      this.product.categoryId = category.id;
      this.features = category.features;
    },
    setFeature(name) {
      const feature = {
        name: name,
        option: this.option
      };
      this.product.features.push(feature);
      this.product.tags.push(this.option);
      this.optionIndex++;
    },
    resetFeatures() {
      (this.option = ""),
        (this.optionIndex = 0),
        // (this.product.features = []),
        // (this.product.tags = []),
        (this.features = []);
    },
    cancel() {
      this.$router.replace("/admin/products/");
    },
    // Save Updated data
    updateProduct(product) {
      this.update({
        collection: "products",
        doc: product.id,
        data: product
      }).then(() => {
        this.snackbar.text = "Updated successfully";
        this.snackbar.color = "success";
        this.snackbar.isActive = true;
      });
    },
    // Slugify for making product URL from name and sku
    slugify,
    // Upload image function
    // uploadImage,
    uploadImage() {
      if (this.images) {
        // console.log(image.target.files[0])
        let file = this.images;
        let time = Date.now();
        // File or Blob named mountains.jpg

        let storageRef = firebase
          .storage()
          .ref("products/" + time + "_" + file.name);

        // Create the file metadata
        let metadata = {
          contentType: "image/jpeg"
        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        let uploadTask = storageRef.put(file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          snapshot => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            this.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            // console.log("Upload is " + this.progress + "% done");
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log("Upload is running");
                break;
            }
          },
          error => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              // console.log('File available at', downloadURL);
              this.progress = 0;
              this.images = null;
              this.product.images.push(downloadURL);
              // Update product images
              this.update({
                collection: "products",
                doc: this.product.id,
                data: {
                  images: this.product.images
                }
              });
              this.snackbar.text = "Uploaded successfully";
              this.snackbar.color = "success";
              this.snackbar.isActive = true;
            });
          }
        );
      } else {
        this.snackbar.text = "Please select an image";
        this.snackbar.color = "warning";
        this.snackbar.isActive = true;
      }
    },
    // Delete image function
    deleteImage(imageUrl, index) {
      // Get Image URL and Reff in firebase storage
      let image = firebase.storage().refFromURL(imageUrl);
      // Delete the file from Firebase Storage
      image
        .delete()
        .then(() => {
          // console.log("File deleted successfully");
          this.product.images.splice(index, 1); //Remove from Data: newProduct.images[]
          // Update product images
          this.update({
            collection: "products",
            doc: this.product.id,
            data: {
              images: this.product.images
            }
          });
          this.snackbar.text = "Image deleted successfully";
          this.snackbar.color = "success";
          this.snackbar.isActive = true;
        })
        .catch(error => {
          this.snackbar.text = "Uh-oh, an error occurred!";
          this.snackbar.color = "error";
          this.snackbar.isActive = true;
          // console.log("Uh-oh, an error occurred!");
        });
    }
  }
};
</script>
